<template>
  <div>
    <div class="mt-[20px] mb-2">
      <BaseElButton data-cy="add-period-btn" plain @click="addTimes">{{ title }}</BaseElButton>
    </div>
    <BaseElFormItem prop="customWeekConfigs" label-width="0">
      <div
        v-for="(item, index) in data"
        :key="index + item.timeStart"
        class="flex align-items-stretch mb-2"
      >
        <BaseElSelect
          v-if="isWeek"
          v-model="item.week"
          placeholder="選擇星期"
        >
          <BaseElSelectOption
            v-for="item in weeksOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
        <el-time-select
          v-model="item.timeStart"
          data-cy="start-time-select"
          class="w-full ml-2"
          placeholder="起始時間"
          :picker-options="{
            start: '00:00',
            step: timeStep,
            end: '24:00',
          }"
        />
        <el-time-select
          v-model="item.timeEnd"
          data-cy="end-time-select"
          class="w-full ml-2"
          placeholder="結束時間"
          :picker-options="{
            start: item.timeStart,
            step: timeStep,
            end: '24:00',
            minTime: item.timeStart,
          }"
        />
        <BaseElButton
          icon="el-icon-close"
          class="p-2 ml-2"
          @click="remove(index)"
        />
      </div>
    </BaseElFormItem>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { weeksOptions } from '@/config/date'

export default defineComponent({
  name: 'AddTimes',
  props: {
    title: String,
    timeStep: String,
    isWeek: Boolean,
    customWeekConfigs: Array,
  },
  emits: [],
  setup (props, { emit }) {
    const data = computed(() => props.customWeekConfigs)
    const emptyTime = { week: null, timeStart: '', timeEnd: '' }
    const remove = (index) => {
      data.value.splice(index, 1)
    }
    const addTimes = () => {
      const times = data.value
      times.push(JSON.parse(JSON.stringify(emptyTime)))
    }
    return { data, addTimes, remove, weeksOptions }
  },
})
</script>
<style lang="postcss" scoped>
</style>
